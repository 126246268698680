.title-changePassword {
    font-size: 38px;
    font-weight: 700;
    color: #007e6d;
    margin: 60px 0 30px 0;
}

.box-ChangePassword {
    padding: 0;
}

@media screen and (max-width: 768px) {
    .title-changePassword {
        margin-top: 80px;
        margin-bottom: 20px;
        padding: 0 20px;
        font-size: 24px;
    }

    .box-ChangePassword {
        padding: 0 20px;
    }
}

@media screen and (max-width: 991px) {
}
