.scrollTopButton {
    position: fixed;
    right: 40px;
    bottom: 60px;
    background: #DC944C;
    color: white;
    padding: 10px 15px;
    z-index: 888;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    gap: 3px;
    opacity: 0.8;
    cursor: pointer;
}

.scrollTopButton:hover {
    opacity: 1;
}

@media screen and (max-width: 500px) {
    .scrollTopButton {
        position: fixed;
        right: 20px;
        bottom: 30px;
        z-index: 888;
    }
}