$xs: 480px;
$tablet-screen: 768px;

.toastWrapper {
  background-color: #fff;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
  color: #54595f;
  margin-bottom: 1rem;
  font-family: Roboto, Sans-serif;

  > button {
    height: fit-content;
    width: fit-content;
    opacity: 1;
    > svg {
      height: 14px;
      width: 14px;
      vertical-align: baseline;
      color: #54595f;
    }
  }

  @media screen and (max-width: $xs) {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.toastBody {
  padding: 0;
  color: #54595f;

  > div:first-of-type {
    align-self: flex-start;
    border-radius: 50%;
    width: 36px;
    height: 36px;
  }
}

.toastSuccessBody {
  > div:first-of-type {
    background-color: #00cf71;

    > svg {
      fill: #dcffeb;
    }
  }
}

.primaryText {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3em;
}

.secondaryText {
  margin-top: 4px;
  color: #54595f;
  font-size: 14px;
}

.warnIcon{
  width: 100%;
  height: 100%;
  fill: #f1c40f;
  color: #f1c40f;
  display: flex;
  align-items: center;
  justify-content: center;
}
