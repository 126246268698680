.loader {
  width: 17px;
  height: 17px;
  margin-left: 10px;
  border: 2px solid white;
  border-radius: 50%;
  border-top: 2px solid transparent !important;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
