.wrapper {
  max-width: 1000px;
}

.portal__content {
  padding: 0 !important;
}

.attachment__section {
  display: flex;
}

.attachment_file {
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}

.application_status {
  font-size: 14px;
  font-weight: bold;
  color: #007e6d;
}

.attachment_file__pdf {
  font-size: 12px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

li::marker {
  color: #de944c;
}

#withdraw-margin {
  margin: 5px 0px 10px 0px;
}

.compition-card {
  position: relative;
  padding: 20px;
  background-color: #f9f9f9;
  border: none;
  border-radius: 10px;
  width: 48%;
}

.portal__card__home {
  background-color: #fff;
  border: 1px solid #e8e1d9;
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 40px;
  position: relative;
}

.bottom-sticky-wrapper {
  width: 101%;
  height: 60px;
  background: white;
  position: sticky;
  bottom: 0px;
  padding-bottom: 25px;
  padding-top: 10px;
  padding-left: 10px;
  left: -25px;
  display: flex;
  margin: 0;
  margin-left: -5px;
}

@media (max-width: 768px) {
  .compition-card {
    margin: 20px 20px 0 20px;
    padding: 20px;
    width: 100%;
  }
}
