.breadcrumps__container {
  display: flex;
  align-items: center;
  margin-right: 0px;
}

.mr30px {
  margin-right: 30px;
}

@media screen and (max-width: 992px) {
  .breadcrumps__container {
    margin-top: 0px;
  }

  .mr30px {
    margin-right: 0;
  }
}

.breadcrumps__arrow {
  margin: 0 10px;
}

/* link */

.breadcrumps__link {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: contents;
  color: #000000;
  text-decoration: none;
  padding-right: 3px;
  padding-left: 3px;
}

@media screen and (max-width: 992px) {
  .breadcrumps__link:last-child {
    padding-bottom: 0;
  }
}

.breadcrumps__link:hover,
.breadcrumps__link:active {
  color: #209898;
}
.breadcrumps__link--inactive {
  pointer-events: none;
  color: inherit;
  text-decoration: none;
  cursor: none;
  font-size: 12px;
  line-height: 16px;
}

/*.breadcrumps__link:after {*/
/*    position: relative;*/
/*    content: '/';*/
/*    padding: 0 2px;*/
/*}*/
