.app-table__container {
  width: 758px;
}
.app-table {
  width: 100%;
  border: 1px solid #b7d1ff;
}
.app-table thead tr {
  background: #f0f4fb;
  border: 1px solid #b7d1ff;
  height: 50px;
}
.app-table tbody tr {
  border-bottom: 1px solid #b7d1ff;
}
.app-table th,
.app-table td {
  height: 50px;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  text-align: left;
  padding: 0 13px;
}

.app-table th {
  font-weight: 500;
}
.app-table td a {
  text-decoration: none;
  color: #212121;
  font-size: 16px;
  line-height: 19px;
}

.NoApplications {
  font-size: 14px;
  font-weight: bold;
}

.text__black {
  color: black;
}

.text__orange {
  color: #de944c;
}

.text__failure {
  color: #dc3545;
}

.accordionList-box {
  margin-bottom: 20px;
}

.accordionList-content-title {
  font-size: 22px;
  cursor: pointer;
  font-weight: 700;
  line-height: 1.2em;
  color: #1f2124;
}

.accordionList-card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
}

.col.my-application {
  max-width: 32%;
  width: 100%;
  margin: 20px 20px 0px 0px;
  border-radius: 10px;
  flex-basis: 25%;
  padding: 20px;
  background-color: #f9f9f9;
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s;
}

@media screen and (min-width: 992px) {
  .col.my-application:nth-child(3n) {
    margin-right: 0;
  }
}

.col.my-application:hover {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.rotate-icon {
  transition: transform 0.3s ease;
  margin-left: 10px;
  margin-bottom: 5px;
}

.rotate-icon-open {
  transform: rotate(180deg);
}

.rotate-icon-close {
  transform: rotate(270deg);
}

@media screen and (max-width: 992px) {
  .accordionList-content-title {
    font-size: 18px;
  }

  .accordionList-box {
    margin-bottom: 10px;
  }

  .col.my-application {
    width: calc(50% - 20px);
    margin: 40px 20px 0 0;
    flex-basis: 100%;
    max-width: calc(50% - 20px);
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .col.my-application {
    width: 100%;
    margin: 20px 0 0 0;
    flex-basis: 100%;
    max-width: 100%;
    display: block;
  }

  .accordionList-card {
    justify-content: normal;
  }
}

@media (max-width: 575px) {
  .accordionList-content-title {
    margin: 0 20px;
  }
}

@media (max-width: 575px) {
  .accordionList-card {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
  }
}
