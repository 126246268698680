.guide-container {
    display: flex;
    flex-direction: column;
}

.guide__header {
    padding: 60px 0 25px;
}

.guide__header h1 {
    font-size: 38px;
    color: #007e6d; /* TODO: set as variable */
    font-weight: 700;
}

.guide__sections {
    width: 100%;
    display: flex;
    height: auto;
    min-height: 100vh;
    margin-bottom: 60px;
}

.guide__navigation-block {
    background: #f9f9f9;
    align-self: stretch;
    padding: 20px;
    border-radius: 10px;
    position: sticky;
    left: 75%;
    top: 180px;
}

.guide__navigation-headline {
    font-size: 18px;
    color: black;
    font-weight: 500;        
    margin-bottom: 15px;
}

.guide__navigation-list ul {
    margin: 0;
    padding: 0;
}

.guide__navigation-list ul li {
    list-style-type: none;
    margin-top: 4px;
    cursor: pointer;
    color: black;
    font-size: 14px;
    gap: 5px;
    display: flex;
    align-items: center;
}

.nav-item_active span, .guide__navigation-list ul li:hover {
    color: #DE944C;
}

.guide__instruction-part-headline {
    margin-bottom: 20px;
    text-align: left;
    font-size: 22px;
    font-weight: 700;
    color: #0e1c36;
}

.guide__instruction-part-description {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20.4px;
}

.guide__instruction-part-image {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.guide__instruction-part-image img {
    width: 50%;
    margin-bottom: 3px;
    border-radius: 10px;
}

.guide__instruction-part-image i {
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .guide__section-instruction {
        width: 75%;
    }

    .guide__section-navigation {
        width: 25%;
        height: auto;
        padding-left: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .guide__section-instruction {
        width: 65%;
    }

    .guide__section-navigation {
        width: 35%;
    }
    .guide__header {
        padding: 80px 0 20px;
    }

    .guide__instruction-part-headline {
        font-size: 18px;
    }

    .guide__instruction-part-image img {
        width: 75%;
    }

    .guide__navigation-block {
        position: sticky;
        top: 85px;
    }
    
}

@media screen and (max-width: 767px) {
    .guide__sections {
        display: flex;
        flex-direction: column-reverse;
    }

    .guide-container {
        padding: 0 20px;
    }

    .guide__header {
        padding: 60px 0 10px;
    }

    .guide__header h1 {
        font-size: 24px;
    }

    .guide__instruction-part-image img {
        width: 100%;
    }
    
    .guide__sections {
        margin-bottom: 20px;
    }
}