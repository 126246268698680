.footer {
  background-color: #007e6d;
  color: #fff;
  display: flex;
  justify-content: center;
}

.footer-wrapper {
  width: 100%;
  padding: 40px 80px;
  display: flex;
  justify-content: flex-start;
  gap: 60px;
}

.footer__contacts p {
    color: #e3e3e3;
    font-size: 14px;
    line-height: 1.2em;
    letter-spacing: .2px;
}

.logowhite {
  width: 120px;
}



@media (max-width: 767px) { 
  .footer-wrapper {
    display: flex;
    flex-direction: column;
    padding: 40px 8px;
    gap: 20px;
  }

  .footer__contacts p {
    font-size: 12px;
  }
}